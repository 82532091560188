import React from 'react'
import './App.css'
import fbase from './util/fbase.js'
import httpandaLogo from './img/HTTPandaLogo.png'

class App extends React.Component {
  constructor (props) {
    super(props);
    this.state = {
      r: this.rand(),
      g: this.rand(),
      b: this.rand(),
      hidden: 'hidden'
    };

    this.reanim = this.reanim.bind(this);
    this.rand = this.rand.bind(this);
    this.newColor = this.newColor.bind(this);
    this.getHex = this.getHex.bind(this);
    this.onKey = this.onKey.bind(this);
  }

  componentDidMount () {
    window.addEventListener('keydown', this.onKey);
    this.reanim();
  }

  componentWillUnmount () {
    window.removeEventListener('keydown', this.onKey);
  }

  reanim () {
    this.setState({
      hidden: 'hidden'
    });

    setTimeout(() => {
      this.setState({
        hidden: ''
      });
    }, 50);
  }

  rand () {
    return Math.floor(Math.random() * 256);
  }

  newColor () {
    this.setState({
      r: this.rand(),
      g: this.rand(),
      b: this.rand()
    });

    this.reanim();
  }

  getHex () {
    let hexR = this.state.r.toString(16);
    hexR = hexR.length === 1 ? '0' + hexR : hexR;
    let hexG = this.state.g.toString(16);
    hexG = hexG.length === 1 ? '0' + hexG : hexG;
    let hexB = this.state.b.toString(16);
    hexB = hexB.length === 1 ? '0' + hexB : hexB;
    return '#' + hexR + hexG + hexB;
  }

  onKey (e) {
    if (e.key === ' ') {
      this.newColor();
    }
  }

  render () {
    return (
      <div className="App">
        <div className="bigColor"
             onClick={this.newColor}
             style={{ backgroundColor: 'rgb(' + this.state.r + ','
                                              + this.state.g + ','
                                              + this.state.b + ')' }}>
        </div>
        <div className={'info ' + this.state.hidden}>
          <div>{this.getHex()}</div>
          <div>rgb({this.state.r},{this.state.g},{this.state.b})</div>
          <div className="dirs">Press screen or spacebar for new color</div>
        </div>
        <a href="https://httpanda.com" target="_blank" className="logoLink">
          <img
            src={httpandaLogo}
            style={{filter: (this.state.r < 80 && this.state.g < 80 && this.state.b < 80) ? 'invert(100%)' : ''}}
          />
        </a>
      </div>
    );
  }
}

export default App;
