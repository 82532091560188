import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyA7nJcPV3J2yt94jolUU50L-8GGi7day0Y",
  authDomain: "randomcolor-httpanda.firebaseapp.com",
  projectId: "randomcolor-httpanda",
  storageBucket: "randomcolor-httpanda.appspot.com",
  messagingSenderId: "885882319391",
  appId: "1:885882319391:web:902aab19b0189de3a69b2a",
  measurementId: "G-KRTPMJXW6H"
};

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
